export const getUTMParameters = () => {
    const params = new URLSearchParams(window.location.search);
    const utmParams = {};

    params.forEach((value, key) => {
        if (key.startsWith('utm_')) {
            utmParams[key] = value;
        } else if(key.startsWith('ad_')) {
            utmParams[key] = value;
        } else {
            //do nothing
        }
    });

    return utmParams;
}