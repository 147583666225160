import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { windowColorFormValiationSchema } from "../validations/validationScheme";
import {
  saveWindowColorFormData,
  setFormStep,
  updateRecord,
} from "../redux/formSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../utils/apiService";

const WindowColor = () => {
  const dispatch = useDispatch();
  const { formId, formStep, windowColorFormData, isLoading } = useSelector(
    (store) => store.form
  );
  
  const [windowColorOptions, setWindowColorOptions] = useState([]);
  const formik = useFormik({
    initialValues: {
      id: formId,
      window_color: windowColorFormData?.window_color || "Black",
      is_custom_color: windowColorFormData?.is_custom_color || "0",
      custom_color: windowColorFormData?.custom_color || "",
      form_step: formStep,
    },
    validationSchema: windowColorFormValiationSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(saveWindowColorFormData(values));
      dispatch(updateRecord(values));
    },
  });

  useEffect(() => {
    getWindowColorOptions();
  }, []);

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    formik;

  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setFormStep(formStep));
  };

  const getWindowColorOptions = async () => {
    const url = `/api/getwindowcolortypes`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setWindowColorOptions(data);
  };

  // State to track whether the checkbox is checked or not
  const [isChecked, setIsChecked] = useState(false);

  // Handle checkbox change event
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checked state
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Window Color</h1>
              <div className="form_note">
                <p>
                  The Push n Pivot Awning Window comes standard with Silver,
                  White, Taupe, Bronze, and Black. You can also get any RAL
                  color for an extra $450
                </p>
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">
                    What color do you want your window to be?
                  </label>
                  <div className="form-check-img-wrap form-check-img-wrap-color">
                    {windowColorOptions.length > 0 &&
                      windowColorOptions.map((item, index) => {
                        return (
                          <div
                            className="form-check"
                            key={`wrapper_window_color${index}`}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="window_color"
                              id={`window_color${index}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onClick={(event) => {
                                if(String(event.target.value).toLowerCase() !== 'custom color' ){
                                  values.custom_color = ""
                                }
                              }}
                              key={"input-"+item.option_label+index}
                              value={item.option_label}
                              defaultChecked= {String(values.window_color).toLowerCase() === String(item.option_label).toLowerCase() }
                            />
                            <label
                              className="form-check-label form-check-img-item"
                              htmlFor={`window_color${index}`}
                            >
                              {item.option_img !== "" ? (
                                <img
                                  src={item.option_img}
                                  alt={item.option_label}
                                />
                              ) : (
                                item.option_label
                              )}
                            </label>
                            {
                              String(item.option_label).toLowerCase() === 'custom color' && String(values.window_color).toLowerCase() === 'custom color'
                              ? <>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="custom_color"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.custom_color}
                                    placeholder="Enter 4 digit RAL number"
                                  />
                                  {touched.custom_color && errors.custom_color ? (
                                    <div className="custom-invalid-feedback">
                                      {errors.custom_color}
                                    </div>
                                  ) : null}
                              </>
                              : null
                            }
                            
                          </div>
                        );
                      })}
                    {touched.window_color && errors.window_color ? (
                      <div className="custom-invalid-feedback">
                        {errors.window_color}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={isLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={isLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default WindowColor;
