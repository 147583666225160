import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formValidationSchema } from "../validations/validationScheme";
import { addOrUpdateForm, saveFormData } from "../redux/formSlice";
import { plainGetAPI } from "../utils/apiService";
import { getUTMParameters } from "../helpers/helpers";

const PrimaryForm = () => {
  const dispatch = useDispatch();
  const {formData, isLoading, disableSubmit, serverErrors, statusCode} = useSelector((store) => store.form );
  const [describesYouOptions, setDescribesYouOptions] = useState([]);
  const [whereYouHeardOptions, setWhereYouHeardOptions] = useState([]);
  const [isZipInvalid, setIsZipInvalid] = useState(false);
  

  const formik = useFormik({
    initialValues: {
      id: "",
      first_name: formData?.first_name || "",
      last_name: formData?.last_name || "",
      email: formData?.email || "",
      phone: formData?.phone || "",
      zipcode: formData?.zipcode || "",
      what_best_describes_you: formData?.what_best_describes_you || "",
      how_did_you_hear_about_us: formData?.how_did_you_hear_about_us || "",
      state: formData.state || "",
      city: formData.city || "",
      utm_params: formData.utmParams || {}
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      checkIfZipIsValid(values.city, values.state);
      if(isZipInvalid) {
        return false;
      }
      dispatch(saveFormData(values));
      dispatch(addOrUpdateForm(values));
    },
  });

  const {
    values,
    setFieldValue,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = formik;

  useEffect(() => {
    getDescribeYouOptions();
    getWhereYouHeardOptions();
  }, []);

  useEffect(() => {
    const utms = getUTMParameters();
    setFieldValue('utm_params', utms);
  }, []);

  const getDescribeYouOptions = async () => {
    const url = `/api/getdescribeyouoptions`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }
    
    setDescribesYouOptions(data);
    
    
  }

  const getWhereYouHeardOptions = async() => {
    const url = `/api/getwhereyouheardoptions`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }
    
    setWhereYouHeardOptions(data);
    

    
  }

  const fetchStateCity = async() => {
    const zip = values.zipcode;
    if(zip == "") {
      return;
    }

    const url = `/api/getcitystate?zip=${zip}`;
    const response = await plainGetAPI(url);
    const { code, message, data } = response;
    if (code !== 200) {
      console.log(message);
      setIsZipInvalid(true);
      return;
    } 
    
    const city = data?.city;
    const state = data?.state;
    setFieldValue('city', city);
    setFieldValue('state', state);
    setIsZipInvalid(false);
  }

  const checkIfZipIsValid = (city, state) => {
    if(city === undefined || state === undefined) {
      console.log('state and city', state, city);
      setIsZipInvalid(true);
    }
  }
  
  return (
    <div className="container">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Online Quoting System</h1>
              <h4 className="form_subhead">
                Get a Price in Less Than 1 Minute for a Push n' Pivot Window!
              </h4>
              <div className="form_note">
                <p>
                  Please fill out the form below so we know where to send your
                  quote. Thank you!
                </p>
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group width_half">
                  <label htmlFor="first_name" className="form-label">
                    First Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                  />
                  {touched.first_name && errors.first_name ? (
                    <div className="custom-invalid-feedback">{errors.first_name}</div>
                  ) : null}
                </div>
                <div className="form-group width_half">
                  <label htmlFor="last_name" className="form-label">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                  />
                  {touched.last_name && errors.last_name ? (
                    <div className="custom-invalid-feedback">{errors.last_name}</div>
                  ) : null}
                </div>
              </div>
              <div className="form_row">
                <div className="form-group width_full">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && errors.email ? (
                    <div className="custom-invalid-feedback">{errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="form_row">
                <div className="form-group width_half">
                  <label htmlFor="phone" className="form-label">
                    Phone*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  {touched.phone && errors.phone ? (
                    <div className="custom-invalid-feedback">{errors.phone}</div>
                  ) : null}
                </div>
                <div className="form-group width_half">
                  <label htmlFor="zipcode" className="form-label">
                    Zip Code*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zipcode"
                    name="zipcode"
                    onChange={(event) => {
                      setFieldValue('zipcode', event.target.value);
                      setIsZipInvalid(false);
                    }}
                    onKeyUp={(event) => {
                      if(event.target.value.length === 5) {
                        fetchStateCity();
                      }
                    }}
                    onBlur={(event) => {
                      setFieldValue('zipcode', event.target.value);
                      fetchStateCity();
                    }}
                    value={values.zipcode}
                  />
                  {touched.zipcode && errors.zipcode ? (
                    <div className="custom-invalid-feedback">{errors.zipcode}</div>
                  ) : null}
                  { isZipInvalid 
                    && <div className="custom-invalid-feedback">We're sorry, but it looks like we don’t currently serve your area. Please double-check the ZIP code entered, or feel free to contact us for more information.</div>
                  }
                </div>
              </div>
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">What best describes you?*</label>
                  {
                    describesYouOptions.length > 0 
                    && describesYouOptions.map((item, index) => {
                      return (<div className="form-check" key={`describesYouOptions-div-${index}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="what_best_describes_you"
                        id={"what_best_describes_you"+index}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        key={"input-"+item+index}
                        value={item}
                        defaultChecked= {String(values.what_best_describes_you).toLowerCase() === String(item).toLowerCase() }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"what_best_describes_you"+index}
                        key={"label-"+item+index}
                      >
                        {item}
                      </label>
                    </div>)
                    })
                  }
                  
                  {touched.what_best_describes_you && errors.what_best_describes_you ? (
                    <div className="custom-invalid-feedback">{errors.what_best_describes_you}</div>
                  ) : null}
                </div>
              </div>
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">
                    How did you hear about us?*
                  </label>
                  {
                    whereYouHeardOptions.length > 0 
                    && whereYouHeardOptions.map((item, index) => {
                      return (<div className="form-check" key={`whereYouHearOptions-div-${index}`}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="how_did_you_hear_about_us"
                          id={"how_did_you_hear_about_us"+index}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={item}
                          key={item}
                          defaultChecked = {String(values.how_did_you_hear_about_us).toLowerCase() === String(item).toLowerCase()}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"how_did_you_hear_about_us"+index}
                        >
                          {item}
                        </label>
                      </div>)
                    })
                  }
                  {touched.how_did_you_hear_about_us && errors.how_did_you_hear_about_us ? (
                    <div className="custom-invalid-feedback">{errors.how_did_you_hear_about_us}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap">
          <input type="hidden" name="id" value={values.id} />
          <input type="hidden" name="state" value={values.state} />
          <input type="hidden" name="city" value={values.city} />
          <button
            type="submit"
            className="btn btn-primary btn_main"
            disabled={isLoading}
          >
            PROCEED TO QUOTE
          </button>
        </div>
      </form>
    </div>
  );
};

export default PrimaryForm;
