import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { motorizedFormValidationSchema } from "../validations/validationScheme";
import WindowScreen from './WindowScreen';
import WindowSill from './WindowSill';
import WindowColor from "./WindowColor";
import {
  saveMotorizedFormData,
  setFormStep,
  updateRecord,
} from "../redux/formSlice";
import { useEffect, useState } from "react";

const Motorized = () => {
  const dispatch = useDispatch();
  const {
    formId,
    formStep,
    motorizedFormData,
    customWindowSizeFormData,
    windowTypeFormData,
    isLoading
  } = useSelector((store) => store.form);
  const [isHighWidthWindow, setIsHighWidthWindow] = useState(false);

  const [motorizedOptions] = useState(["Yes", "No"]);
  const formik = useFormik({
    initialValues: {
      id: formId,
      is_motorized: motorizedFormData?.is_motorized || "",
      form_step: formStep,
    },
    validationSchema: motorizedFormValidationSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(saveMotorizedFormData(values));
      dispatch(updateRecord(values));
    },
  });

  useEffect(() => {
    if (
      String(windowTypeFormData.window_type).toLowerCase() === "custom sizes" &&
      parseInt(customWindowSizeFormData.custom_width) > 108
    ) {
      setIsHighWidthWindow(true);
    }
  }, []);

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    formik;

  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setFormStep(formStep));
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Motorized?</h1>
              <div className="form_note">
                <p>
                  Windows with a width greater than 108" and a height greater
                  than 60" MUST be motorized.{" "}
                </p>
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">
                    Would you like your window to be Motorized?
                  </label>
                  {motorizedOptions.length > 0 &&
                    motorizedOptions.map((item, index) => {
                      return (
                        <div className="form-check" key={`wrapper_is_motorized${index}`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_motorized"
                            id={"is_motorized" + index}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={item}
                            defaultChecked={
                              String(values.is_motorized).toLowerCase() ===
                              String(item).toLowerCase()
                            }
                            disabled={item==='No' && isHighWidthWindow}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"is_motorized" + index}
                          >
                            {item}
                          </label>
                        </div>
                      );
                    })}
                  {touched.is_motorized && errors.is_motorized ? (
                    <div className="custom-invalid-feedback">
                      {errors.is_motorized}
                    </div>
                  ) : null}
                </div>
              </div>
              {
                (isHighWidthWindow)
                && <h5 className="bold-red">You must choose motorized for windows of this size.</h5>
              }
              
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={isLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={isLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Motorized;
