import { useDispatch, useSelector } from "react-redux";
import { resetAndRestart, setFinalQuote, setFormStep, updateRecord } from "../redux/formSlice";
import { useEffect } from "react";
import { plainGetAPI } from "../utils/apiService";
import ReactPixel from 'react-facebook-pixel';

const FinalQuoteScreen = () => {
  const dispatch = useDispatch();
  const { formId, formStep, formData, windowTypeFormData, standardWindowSizeFormData, customWindowSizeFormData, motorizedFormData, windowSillFormData, windowScreenFormData, windowColorFormData, quantityFormData, finalQuote } = useSelector(
    (store) => store.form
  );

  useEffect(() => {
    // console.log(formData, windowTypeFormData, standardWindowSizeFormData, customWindowSizeFormData, motorizedFormData, quantityFormData, finalQuote );
    calculateFinalQuote();
  },[]);

  const calculateFinalQuote = async () => {
    const {window_type} = windowTypeFormData;
    let width = 0;
    let height = 0;
    let motorized = false;
    let custom = false;
    let quote = formData?.quote_number;
    const {quantity} = quantityFormData;
    
    let endPoint = 'calculate_custom_cost';

    if(String(window_type).toLowerCase() === 'standard sizes') {
        const {standard_size} = standardWindowSizeFormData;
        const fetchWidthHeight = fetchDimentions(standard_size);
        width = fetchWidthHeight.width;
        height = fetchWidthHeight.height;
    } else {
        width = parseFloat(customWindowSizeFormData.custom_width);
        height = parseFloat(customWindowSizeFormData.custom_height);
        custom = true;
    }

    if(String(motorizedFormData.is_motorized).toLowerCase() === 'yes') {
        motorized = true;
    }

    const isLocalCall = true;
    
    let targetUrl = `https://calculator.mostinnovativedoors.com/backend/${endPoint}?width=${width}&height=${height}&motorized=${motorized}&custom=${custom}&quantity=${quantity}&quote=${quote}&is_local_call=${isLocalCall}&form_id=${formId}`;

    if(String(windowSillFormData.window_sill).toLowerCase() !== 'no sill') {
      targetUrl += `&window_sill=${windowSillFormData.window_sill}`;
    }

    if(String(windowScreenFormData.window_screen).toLowerCase() !== 'no screen') {
      targetUrl += `&window_screen=${windowScreenFormData.window_screen}`;
    }

    if(String(windowColorFormData.window_color).toLowerCase() === 'custom color') {
      targetUrl += `&window_color=${windowColorFormData.custom_color}`;
    }

    const response = await plainGetAPI(targetUrl);
    
    const { estimated_cost } = response;
    if (estimated_cost !== undefined) {
      try {
        ReactPixel.track('Lead', {content_type: "Windows Calculator"});
        console.log('Lead Event Tracked');
      } catch(error) {
        console.log(error, 'Error in Lead tracking');
      }
      
      dispatch(setFinalQuote(estimated_cost));
      // Trigger Facebook Lead Event
    } else {
      // dispatch(setFinalQuote(estimated_cost));
      console.log('error in fetching the final quote');
    }
    
    // console.log(data);
  }

  const fetchDimentions = (standardSize) => {
    const splitDimention = standardSize.split('x');
    const width = String(splitDimention[0]).replace('"w', '').trim();
    const height = String(splitDimention[1]).replace('"h', '').trim();
    return {
        'width': parseFloat(width),
        'height': parseFloat(height)
    };
  }

  const clearAllAndRestart = () => {
    dispatch(resetAndRestart());
  }
  
  return (
    <div className="main_wrap">
        <div className="form_step_wrap">
        <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Total Cost</h1>
              <h4 className="form_subhead">
                { finalQuote === 0 
                ? 'Please Wait..'
                : '$'+finalQuote 
                }</h4>
              <div className="form_note">
                <p>We will email you a copy of your estimate and our team will reach out shortly for any questions you may have.</p>
                <p>Our team is standing by 9am - 5pm CST Monday - Friday. We have many options, suitable for any project.</p>
                <p>You can reach us at (888) 472-5793 or visit our website at https://mostinnovativedoors.com.</p>
                <p>Thank you!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button type="button" className="btn btn-primary btn_sec w-auto" onClick={clearAllAndRestart}>
            <i className="bi bi-chevron-left"></i> Get New Quote
          </button>
          {/* <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
          >
            Next <i className="bi bi-chevron-right"></i>
          </button> */}
        </div>
    </div>
  );
};

export default FinalQuoteScreen;
