import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { windowScreenFormValidationSchema } from "../validations/validationScheme";
import {
  saveWindowScreenFormData,
  setFormStep,
  updateRecord,
} from "../redux/formSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../utils/apiService";

const WindowScreen = () => {
  const dispatch = useDispatch();
  const {
    formId,
    formStep,
    windowScreenFormData,
    isLoading
  } = useSelector((store) => store.form);
  
  const [windowScreenOptions, setWindowScreenOptions] = useState([]);
  const formik = useFormik({
    initialValues: {
      id: formId,
      window_screen: windowScreenFormData?.window_screen || "No Screen",
      form_step: formStep,
    },
    validationSchema: windowScreenFormValidationSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(saveWindowScreenFormData(values));
      dispatch(updateRecord(values));
    },
  });

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    formik;

  useEffect(() => {
    getWindowScreenOptions();
  }, []);

  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setFormStep(formStep));
  };

  const getWindowScreenOptions = async() => {
    const url = `/api/getwindowscreentypes`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setWindowScreenOptions(data);
  }

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Window Screen</h1>
              <div className="form_note">
                <p>
                The Push n Pivot Awning Window comes standard with no screen.
                </p>
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">
                    Would you like to add a screen (for an additional cost)?
                  </label>
                  {windowScreenOptions.length > 0 &&
                    windowScreenOptions.map((item, index) => {
                      return (
                        <div className="form-check" key={`window_screen${index}`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="window_screen"
                            id={"window_screen" + index}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={item}
                            defaultChecked={
                              String(values.window_screen).toLowerCase() ===
                              String(item).toLowerCase()
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"window_screen" + index}
                          >
                            {item}
                          </label>
                        </div>
                      );
                    })}
                  {touched.window_screen && errors.window_screen ? (
                    <div className="custom-invalid-feedback">
                      {errors.window_screen}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={isLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={isLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default WindowScreen;
