import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { windowSillFormValiationSchema } from "../validations/validationScheme";
import {
  saveWindowSillFormData,
  setFormStep,
  updateRecord,
} from "../redux/formSlice";
import { useEffect, useState } from "react";
import { plainGetAPI } from "../utils/apiService";

const WindowSill = () => {
  const dispatch = useDispatch();
  const { formId, formStep, windowSillFormData, isLoading } = useSelector(
    (store) => store.form
  );
  
  const [windowSillOptions, setWindowSillOptions] = useState([]);

  const formik = useFormik({
    initialValues: {
      id: formId,
      window_sill: windowSillFormData?.window_sill || "No Sill",
      form_step: formStep,
    },
    validationSchema: windowSillFormValiationSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(saveWindowSillFormData(values));
      dispatch(updateRecord(values));
    },
  });

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    formik;

  useEffect(() => {
    getWindowSillOptions();
  }, []);

  const getWindowSillOptions = async () => {
    const url = `/api/getwindowsilltypes`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setWindowSillOptions(data);
  };

  const nextStep = () => {
    handleSubmit();
  };

  const handlePrevious = () => {
    dispatch(setFormStep(formStep));
  };

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
          <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Window Sill</h1>
              <div className="form_note">
                <p>
                  The Push n Pivot Awning Window comes standard with no sill.
                </p>
              </div>
            </div>
            <div className="form_wrap">
              <div className="form_row">
                <div className="form-group">
                  <label className="lbl_main">
                    Would you like to add a sill (for an additional cost)?
                  </label>
                  <div className="form-check-img-wrap">
                    {windowSillOptions.length > 0 &&
                      windowSillOptions.map((item, index) => {
                        return (
                          <div className="form-check" key={`window_sill_${index}`}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="window_sill"
                              id={`window_sill${index}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              key={"input-"+item+index}
                              value={item.option_label}
                              defaultChecked= {String(values.window_sill).toLowerCase() === String(item.option_label).toLowerCase() }
                            />
                            <label
                              className="form-check-label form-check-img-item"
                              htmlFor={`window_sill${index}`}
                            >
                              {item.option_img !== "" ? (
                                <img
                                  src={item.option_img}
                                  alt={item.option_label}
                                />
                              ) : (
                                item.option_label
                              )}
                            </label>
                          </div>
                        );
                      })}
                    {touched.window_sill &&
                    errors.window_sill ? (
                      <div className="custom-invalid-feedback">
                        {errors.window_sill}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn_sec w-auto"
            onClick={handlePrevious}
            disabled={isLoading}
          >
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="btn btn-primary btn_main w-auto"
            disabled={isLoading}
          >
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default WindowSill;
