import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { standardSizeFormValidationSchema } from "../validations/validationScheme";
import { plainGetAPI } from "../utils/apiService";
import { saveStandardWindowSizeFormData, setFormStep, updateRecord } from "../redux/formSlice";

const StandardWindowSize = () => {
    const dispatch = useDispatch();
  const { formId, formStep, standardWindowSizeFormData, isLoading } = useSelector((store) => store.form);
  const [standardSizes, setStandardSizes] = useState([]);
  const formik = useFormik({
    initialValues: {
      id: formId,
      standard_size: standardWindowSizeFormData?.standard_size || "",
      form_step: formStep
    },
    validationSchema: standardSizeFormValidationSchema,
    onSubmit: (values) => {
        console.log(values);
        dispatch(saveStandardWindowSizeFormData(values));
        dispatch(updateRecord(values));
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
 } = formik;

  useEffect(() => {
    getStandardSizes();
  }, []);

  const getStandardSizes = async () => {
    const url = `/api/getstandardsizes`;
    const list = await plainGetAPI(url);
    const { code, message, data } = list;
    if (code !== 200) {
      console.log(message);
    }

    setStandardSizes(data);
  };

  const nextStep = () => {
    handleSubmit();
  }

  const handlePrevious = () => {
    dispatch(setFormStep(formStep));
  }

  return (
    <div className="main_wrap">
      <form className="main_form_wrap" onSubmit={handleSubmit}>
        <div className="form_step_wrap">
        <div className="form_step_item">
            <div className="form_head_cont">
              <h1 className="form_head">Standard Window Sizes</h1>
            </div>
            <div className="form_wrap">
              <div className="form_row justify-content-center">
                <div className="form-group width_half">
                  <label className="lbl_main">Select Standard Size*</label>
                  <select 
                    className="form-select" 
                    aria-label="Default select example"
                    name="standard_size"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.standard_size}
                    >
                    <option value="">Select Size</option>  
                    {
                        standardSizes.length
                        && standardSizes.map((item, index) => {
                            return(
                                <option value={item} key={item}>{item}</option>
                            )
                        })
                    }
                  </select>
                  {touched.standard_size && errors.standard_size ? (
                            <div className="custom-invalid-feedback">{errors.standard_size}</div>
                          ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_wrap btn_two_wrap d-flex justify-content-center">
          <button type="button" className="btn btn-primary btn_sec w-auto" onClick={handlePrevious} disabled={isLoading}>
            <i className="bi bi-chevron-left"></i> Back
          </button>
          <button type="button" onClick={nextStep} className="btn btn-primary btn_main w-auto" disabled={isLoading}>
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default StandardWindowSize;