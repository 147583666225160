import './App.css';
import Header from './components/header';
import MultiStepForm from './MultiStepForm';
import Footer from './components/footer';
import FacebookPixel from './Analytics/FacebookPixel';

function App() {
  
  return (
    <div className="App">
      <Header />
      <FacebookPixel />
      <MultiStepForm />
      <Footer />
    </div>
  );
}

export default App;
