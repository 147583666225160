import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../axios/axiosConfig";
import axios from "axios";
// initial, windowType, standardWindowSize, customWindowSize, extraLargeWindowSize
const initialState = {
  formData: {},
  windowTypeFormData: {},
  standardWindowSizeFormData: {},
  customWindowSizeFormData: {},
  motorizedFormData: {},
  windowSillFormData: {},
  windowScreenFormData: {},
  windowColorFormData: {},
  quantityFormData: {},
  finalQuoteFormData: {},
  formStep: "initial",
  finalQuote: 0,
  formId: null,
  isLoading: false,
  disableSubmit: false,
  serverErrors: {},
  statusCode: null,
};

export const addOrUpdateForm = createAsyncThunk(
  "record/addOrEdit",
  async (record, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        BASE_URL + "/api/addoreditenquiry",
        record
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRecord = createAsyncThunk(
  "record/update",
  async (record, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        BASE_URL + "/api/updateenquiry",
        record
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// initial, windowType, standardWindowSize, customWindowSize, extraLargeWindowSize, motorized, quantity
const getLastForm = (lastStep) => {
  switch (lastStep) {
    case "initial":
      return "formData";
    case "windowType":
      return "windowTypeFormData";
    case "standardWindowSize":
      return "standardWindowSizeFormData";
    case "customWindowSize":
      return "customWindowSizeFormData";
    case "motorized":
      return "motorizedFormData";
    case "windowSill":
      return "windowSillFormData";
    case "windowScreen":
      return "windowScreenFormData";
    case "windowColor":
      return "windowColorFormData";
    case "quantity":
      return "quantityFormData";
    case "finalQuote":
      return "finalQuoteFormData";
    default:
      return "formData";
  }
};

const formSlice = createSlice({
  name: "form1",
  initialState,
  reducers: {
    saveFormData: (state, action) => {
      state.formData = action.payload;
    },
    saveWindowTypeFormData: (state, action) => {
      state.windowTypeFormData = action.payload;
    },
    saveStandardWindowSizeFormData: (state, action) => {
      state.standardWindowSizeFormData = action.payload;
    },
    saveCustomWindowSizeFormData: (state, action) => {
      state.customWindowSizeFormData = action.payload;
    },
    saveMotorizedFormData: (state, action) => {
      state.motorizedFormData = action.payload;
    },
    saveWindowSillFormData: (state, action) => {
      state.windowSillFormData = action.payload;
    },
    saveWindowScreenFormData: (state, action) => {
      state.windowScreenFormData = action.payload;
    },
    saveWindowColorFormData: (state, action) => {
      state.windowColorFormData = action.payload;
    },
    saveQuantityFormData: (state, action) => {
      state.quantityFormData = action.payload;
    },
    setFormStep: (state, action) => {
        switch(action.payload) {
            case 'extraLargeWindowSize':
            case 'standardWindowSize':
            case 'customWindowSize':
                state.formStep = 'windowType';
                return;
            case 'motorized':
                if(state.standardWindowSizeFormData?.standard_size) {
                    state.formStep = 'standardWindowSize';
                } else {
                    state.formStep =  'customWindowSize';
                }    
                return;
            case 'windowSill':
              state.formStep = 'motorized';
              return;
            case 'windowScreen':
              state.formStep = 'windowSill';
              return;
            case 'windowColor':
              state.formStep = 'windowScreen';
              return;
            case 'quantity':
                state.formStep = 'windowColor';
                return;
            case 'finalQuote':
                state.formStep = 'quantity';
                return;
            default:
                state.formStep = 'initial';
                return;
    
        }
    },
    setFinalQuote: (state, action) => {
        state.finalQuote = action.payload;
    },
    resetAndRestart: (state, action) => {
      state.formData =  {};
      state.windowTypeFormData = {};
      state.standardWindowSizeFormData = {};
      state.customWindowSizeFormData= {};
      state.motorizedFormData = {};
      state.quantityFormData = {};
      state.windowSillFormData = {};
      state.windowScreenFormData = {};
      state.windowColorFormData = {};
      state.formStep = "initial";
      state.finalQuote = 0;
      state.formId = null;
      state.isLoading = false;
      state.disableSubmit = false;
      state.serverErrors =  {};
      state.statusCode = null;
    }
  },
  extraReducers: (builder) => {
    // First Step Actions
    builder.addCase("addOrUpdateForm.pending", (state) => {
      state.isLoading = true;
    });

    builder.addCase(addOrUpdateForm.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      state.formData = response?.data;
      state.formId = response?.data?.id;
      state.formStep = "windowType";
    });

    builder.addCase(addOrUpdateForm.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    // First Step Actions

    // Update only actions
    builder.addCase("updateRecord.pending", (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateRecord.fulfilled, (state, action) => {
      const lastForm = getLastForm(state.formStep);
      const response = action.payload;
      state.isLoading = false;
      state[lastForm] = response?.data;
      state.formId = response?.data?.id;
      state.formStep = response?.data?.next_step;
      console.log(state);
    });

    builder.addCase(updateRecord.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    // Update only actions
  },
});

export const {
  saveFormData,
  saveWindowTypeFormData,
  saveStandardWindowSizeFormData,
  saveCustomWindowSizeFormData,
  saveMotorizedFormData,
  saveWindowSillFormData,
  saveWindowScreenFormData,
  saveWindowColorFormData,
  saveQuantityFormData,
  setFormStep,
  setFinalQuote,
  resetAndRestart
} = formSlice.actions;
export default formSlice.reducer;
