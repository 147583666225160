// MultiStepForm.js
import React, { useState } from 'react';
import PrimaryForm from './components/PrimaryForm';
import { useSelector } from 'react-redux';
import WindowType from './components/WindowType';
import StandardWindowSize from './components/StandardWindowSize';
import CustomWindowSize from './components/CustomWindowSize';
import Motorized from './components/Motorized';
import WindowScreen from './components/WindowScreen';
import WindowSill from './components/WindowSill';
import WindowColor from './components/WindowColor';
import Quantity from './components/Quantity';
import ExtraLargeWindowSize from './components/ExtraLargeWindowSize';
import FinalQuoteScreen from './components/FinalQuoteScreen';

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const {formStep} = useSelector((store) => store.form);

// initial, windowType, standardWindowSize, customWindowSize, extraLargeWindowSize, motorized, quantity, finalQuote
  switch (formStep) {
    case 'initial':
      return <PrimaryForm />;
    case 'windowType':
      return <WindowType />
    case 'standardWindowSize':
      return <StandardWindowSize />
    case 'customWindowSize':
        return <CustomWindowSize />
    case 'extraLargeWindowSize':
      return <ExtraLargeWindowSize />
    case 'motorized':
        return <Motorized />
    case 'windowSill':
        return <WindowSill />
    case 'windowScreen':
        return <WindowScreen />
    case 'windowColor':
        return <WindowColor />
    case 'quantity':
      return <Quantity />
    case 'finalQuote':
      return <FinalQuoteScreen />
    default:
      return <div>Invalid step</div>;
  }
};

export default MultiStepForm;
